@use "node_modules/bulma/sass/utilities/initial-variables";
@use 'node_modules/ngx-toastr/toastr';
@use 'node_modules/tippy.js/dist/tippy.css';
@use 'node_modules/tippy.js/animations/scale.css';

$link : #2e7d86;
$primary: #08821e;
$danger: #86312e;
$warning: #E49B0F;
$frog-green1: #007237;
$frog-green2: #2E8637;
$table-head-background-color: #f1f1f1; 
$tooltip-color: rgba(8,130,30,0.9);


@font-face {
    font-family: 'FrogFont';
    src: url('assets/fonts/Aller_Rg.ttf');
}

$top-nav-height: 52px;
$page-header-height: 75px;
$left-nav-width: 15vw;
:root 
{
    --frog-green1: #3b6d40;
    --frog-green2: #2E8637;
    --frog-danger: #86312e;
    --frog-warning: #E49B0F;
    --border: #dbdbdb;
    --border-color: #aaa;
    --panel-background: #eee;
}

@import "node_modules/bulma/bulma";

html {
    overflow-y: auto !important;
}

body{
    font-family: 'FrogFont' !important;
}

.is-display{
    border: none;
    box-shadow: none;
}

.disabled {
    background-color: whitesmoke;
    border-color: whitesmoke;
    box-shadow: none;
    color: #7a7a7a;
    pointer-events: none;
  }

.page-title{
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    padding: 0.25em;
    padding-left: 1em;
}
.mainbody{
    position: fixed; 
    top: 52px;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
}
.left-nav{
    background-color: #eee;
    border-right: solid 1px #ddd;
    position: fixed;
    left: 0;
    width: $left-nav-width;
    top: $top-nav-height;
    bottom: 0;
    overflow-y: auto;
    padding: 0;
}
.maincontent{
    position: fixed;
    left: $left-nav-width;
    padding-left:1vw;
    padding-right:1vw;
    padding-top:1vw;
    right: 0;
    top: $top-nav-height + $page-header-height;
    bottom: 0;
    overflow: auto;
    .pageheader{
        position: fixed;
        left: $left-nav-width;
        top: $top-nav-height;
        right: 0;
        height: $page-header-height;
    }
    &.fullpage {
        top: 45px;  // Override top position for fullpage content
        
        .pageheader {
            display: none;  // Hide the pageheader for fullpage content
        }
    }
}

@media screen and (max-width: 1024px), print{
    .left-nav{
        display: none;
    }
    .maincontent{
        left: 0;
    }
}

.input{
    max-width: 40em;
    &.is-short{
        max-width: 20em
    }
    &.is-shorter{
        max-width: 7em;
    }
    &.is-shortest{
        max-width: 3em;
    }
    &.is-fullwidth{
        max-width: 100%;
    }
}

body{
    opacity: 1;
}

.modal-content, .modal-card{
    width: auto;
    
}
.modal-card-foot{
    justify-content: right;
}
.loading-container{
    position: relative;
}
.toast-success{
    background-color: var(--frog-green2);
}
.toast-error{
    background-color: var(--frog-danger);
}

.ng-autocomplete {
    .autocomplete-container {
        border: none;
        box-shadow: none;
        width: 100%; /* Set width to 100% for full width */
        
        .input-container {
            input {
                @extend .input,.is-fullwidth
            }
        }
    }
}

/*   */
@media screen and (min-width: 769px), print{
    form{
        .subtitle{
            margin-top: 2em;
        }
        checkbox{
            position: relative;
            vertical-align: bottom;
        }
    }
    form{
        .field-label {
            flex-grow: 0;
            min-width:16em;
        }
    }   
    .tabs{
        .field-label {
            flex-grow: 0;
            min-width:12em;
        }
    }
       
}

/*Z INDEXES IN ONE PLACE TO EASILY SEE*/
.modal{
    z-index: 50
}



/* DEVICE STATUS COLORS */
.device-status-Ok{
    background: linear-gradient(90deg, #f1f1f1 40%, #ddd 80%, #C7DBC9);
}
.device-status-Degraded{
    background: linear-gradient(90deg, #f1f1f1 40%, #ddd 80%, #D2D3A7);
}
.device-status-Fault{
    background: linear-gradient(90deg, #f1f1f1 40%, #ddd 80%, #D9CCB3);
}
.device-status-Offline{
    background: linear-gradient(90deg, #f1f1f1 40%, #ddd 80%, #C8AE9D);
}

.panel{
    .panel-block{
        .label{
            display: inline-block;
            width: 15em;
            padding-right: 1em;
            overflow: hidden;
            font-weight: bold;
            margin: 0;
            text-align: right;
        }
    }
}

.flex-grow {
    flex-grow: 1;
}

.input-table {
 width: 100%;
}

.input-table tbody tr td .select {
    width: 100%;
}

.input-table tbody tr td {
    width: 32%;
}

.flex-leading-sm {
    width:4em;
    margin-right:0 !important;
}

.flex-leading-sm input {
    width:4em;
}

//.panel{
    //border: solid 1px #e4e9e4;
//}
.panel-heading{
    padding: 0.5em;
    padding-left: 2em;
    font-size: 1.1em;
    font-weight: 400;
    //background-color: #e4e9e4;
}

.tags:not(:last-child) {
    margin-bottom: -0.5rem;
}

.tags:not(:first-child){
    margin-left:10px;
}

.tags{
    margin-top:5px !important;
    margin-bottom:5px !important;
}

a.tag{
    text-decoration: none;
}

.addComponent{
    border-style: outset;
    border-width: 3px;
    position: inherit;
    padding: 16px;
    width: 70%;
    margin-top: 20px;
}

.tippy-box[data-theme~=translucent]{background-color:$tooltip-color}
.tippy-box[data-theme~=translucent]>.tippy-arrow{width:14px;height:14px}
.tippy-box[data-theme~=translucent][data-placement^=top]>.tippy-arrow:before{border-width:7px 7px 0;border-top-color:$tooltip-color}
.tippy-box[data-theme~=translucent][data-placement^=bottom]>.tippy-arrow:before{border-width:0 7px 7px;border-bottom-color:$tooltip-color}
.tippy-box[data-theme~=translucent][data-placement^=left]>.tippy-arrow:before{border-width:7px 0 7px 7px;border-left-color:$tooltip-color}
.tippy-box[data-theme~=translucent][data-placement^=right]>.tippy-arrow:before{border-width:7px 7px 7px 0;border-right-color:$tooltip-color}
.tippy-box[data-theme~=translucent]>.tippy-backdrop{background-color:$tooltip-color}
.tippy-box[data-theme~=translucent]>.tippy-svg-arrow{fill:$tooltip-color}

.toggleTag{
    margin-top: -12px
}

.panel-tabs:not(:last-child), .panel-block:not(:last-child) {
    border-bottom: none !important;
}

.modal-slide-transition {
    transition: width 0.3s ease-in-out;
}

.full-width {
    width: 100% !important;
}

.half-width {
    width: 50% !important;
}

.three-quarters-width {
    width: 75% !important;
}

.Icon{
    height: 100%;
    width: 2em;
    border-color: 1px solid hsl(0, 0%, 86%);
    margin-top: 5px;
    text-align: center;
    font-size: 1em;
}

.Flash.Medium{
    animation: flashAnimation 0.6s infinite alternate; /* Flash animation */
  }
  
  .Flash.High{
    animation: flashAnimation 0.3s infinite alternate; /* Flash animation */
  }
  
  @keyframes flashAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.3; 
        color:black;
    }
  }

  .modal-background {
    background-color: rgba(10, 10, 10, 0.5) !important;
}

.frog-scrollbar {
    // Webkit browsers (Chrome, Safari, newer Edge)
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--frog-green2);
        border-radius: 12px;
    }

    // Firefox
    scrollbar-width: thin;
    scrollbar-color: var(--frog-green2) transparent;
}
